/* Code */
.notepad {
	width: 100%;
	min-height: 300px;
}
.person-table tr td:first-child {
	font-weight: bold;
}
.form-person {
	border-top: 1px solid #ddd;
	margin: 20px 0;
	padding: 20px 0;
}
.footer {
	border-top: 1px solid #ddd;
	margin-top: 20px;
	padding: 5px 0;
}

.footer p, 
.footer ul {
	margin-bottom: 0;
}

.footer-menu {
	float: right;
	list-style: none;
}
.footer-menu li {
	float: left;
	
}
.footer-menu li a {
	border-left: 1px solid #ddd;
	padding: 0 5px;
	display: inline-block;
}
.footer-menu li:first-child a {
	border-left: 0;
}
.breadcrumb {
	margin-bottom: 0;
	background-color: #fff;
}

.sticky-left ul li {
	float: none;
	margin: 2px;
}

.sticky-left ul li a {
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

.sticky-left li a i {
	font-size: 22px;
	line-height: 50px;
}

.btn-hide {
	font-size: 20px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	display: block;
	text-align: center;
	cursor: pointer;
	position: fixed;
	left: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	margin: -50px 2px 2px 2px;
	-webkit-transition: all 500ms ease-out 100ms;
	-moz-transition: all 500ms ease-out 100ms;
	-o-transition: all 500ms ease-out 100ms;
	transition: all 500ms ease-out 100ms;
}

.btn-hide.active,
.btn-hide:hover {
	background: rgba(49, 49, 49, 0.03);
	color: #ff0000;
}

.btn-hide.active {
	-ms-transform: translate(0,100%);
	-webkit-transform: translate(0,100%);
	transform: translate(0,100%);
}

.sticky-social .btn-hide.active i:before {
	content: "\f1e0";
}

.sticky-left.hide-social {
	-ms-transform: translate(-100%,0);
	-webkit-transform: translate(-100%,0);
	transform: translate(-100%,0);
}
/*--------------------------------------------------------------
Social Sharing Button
--------------------------------------------------------------*/
.gs-social-sharing {
	padding: 0;
	margin: 0;
	list-style: none;
}

.before-content,
.after-content {
	margin-top: 15px;
}

.single .entry-content .after-content ul {
	margin-bottom: 0;
}

.gs-social-sharing li a i {
	font-size: 16px;
	line-height: 28px;
	position: relative;
	z-index: 2;
}

.gs-social-sharing ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.gs-social-sharing li {
	float: left;
	width: 20%;
}

.gs-social-sharing li:last-child {
	margin-right: 0;
}

.gs-social-sharing li a {
	-webkit-font-smoothing: antialiased;
	-webkit-transition: background-color .2s ease-in-out;
	-moz-transition: background-color .2s ease-in-out;
	-o-transition: background-color .2s ease-in-out;
	transition: background-color .2s ease-in-out;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	font-size: 18px;
	height: 28px;
	text-align: left;
	text-decoration: none;
	line-height: 28px;
	display: block;
	color: #ffffff;
	font-weight: bold;
	padding: 0 10px;
	margin-right: 3px;
}

.gs-social-sharing li a:before {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.gs-social-sharing li a span {
	position: relative;
	z-index: 2;
	font-size: 12px;
	float: right;
}

.gs-social-sharing .facebook {
	background-color: rgba(61, 90, 152, 1);
}

.gs-social-sharing .twitter {
	background-color: rgba(9, 176, 237, 1);
}
.gs-social-sharing .plusone {
	background-color: rgba(209, 71, 53, 1)
}
.gs-social-sharing .pinterest {
	background-color: rgba(203, 33, 39, 1)
}
.gs-social-sharing .linkedin {
	background-color: rgba(3, 90, 135, 1)
}
.gs-social-sharing .linkedin i {
	margin-top: -2px;
}
.gs-social-sharing .pinterest i {
	margin-top: -1px;
}

.gs-social-sharing .facebook:before {
	border-top: 70px solid #324A7E;
	border-right: 70px solid transparent;
}

.gs-social-sharing .facebook:hover:before {
	border-top: 150px solid #324A7E;
	border-right: 186px solid transparent;
}

.gs-social-sharing .twitter:before {
	border-top: 70px solid #0690C2;
	border-right: 70px solid transparent;
}

.gs-social-sharing .twitter:hover:before {
	border-top: 150px solid #0690C2;
	border-right: 186px solid transparent;
}

.gs-social-sharing .plusone:before {
	border-top: 70px solid #e75c3c;
	border-right: 70px solid transparent;
}
.gs-social-sharing .plusone:hover:before {
	border-top: 150px solid #e75c3c;
	border-right: 186px solid transparent;
}

.gs-social-sharing .pinterest:before {
	border-top: 70px solid #AD1B20;
	border-right: 70px solid transparent;
}
.gs-social-sharing .pinterest:hover:before {
	border-top: 150px solid #AD1B20;
	border-right: 186px solid transparent;
}

.gs-social-sharing .linkedin:before {
	border-top: 70px solid #03496E;
	border-right: 70px solid transparent;
}
.gs-social-sharing .linkedin:hover:before {
	border-top: 150px solid #03496E;
	border-right: 186px solid transparent;
}

/* Sticky Left */
.sticky-social {
	position: absolute;
	left: 0;
	top: 160px;
	z-index: 99999;
}

.sticky-left {
	position: fixed;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
	-webkit-transition: -webkit-transform 500ms ease;
	transition: transform 500ms ease;
}

.sticky-left ul li {
	float: none;
	margin: 2px;
}

.sticky-left ul li a {
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}

.sticky-left li a i {
	font-size: 22px;
	line-height: 50px;
}

.btn-hide {
	font-size: 20px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	display: block;
	text-align: center;
	cursor: pointer;
	position: fixed;
	left: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	margin: -50px 2px 2px 2px;
	-webkit-transition: all 500ms ease-out 100ms;
	-moz-transition: all 500ms ease-out 100ms;
	-o-transition: all 500ms ease-out 100ms;
	transition: all 500ms ease-out 100ms;
}

.btn-hide.active,
.btn-hide:hover {
	background: rgba(49, 49, 49, 0.03);
	color: #ff0000;
}

.btn-hide.active {
	-ms-transform: translate(0,100%);
	-webkit-transform: translate(0,100%);
	transform: translate(0,100%);
}

.sticky-social .btn-hide.active i:before {
	content: "\f1e0";
}

.sticky-left.hide-social {
	-ms-transform: translate(-100%,0);
	-webkit-transform: translate(-100%,0);
	transform: translate(-100%,0);
}
.copy-action {
	text-align: center;
	padding: 10px 0;
}